import type { RouteObject } from "react-router-dom";
import { Navigate, createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import RouteError from "./components/RouteError";
import { throwErrorResponse } from "./helpers";
import appLoader from "./views/App/loader";
import homeLoader from "./views/Home/loader";
import questionnaireLoader from "./views/Questionnaires/questionnaireLoader";
import questionnaireDetailsLoader from "./views/Questionnaire/Details/loader";
import resourcesLoader from "./views/Resources/loader";
import workspaceSettingsLoader from "./views/WorkspaceDetails/Settings/loader";
import reportsLoader from "./views/Reports/reportsLoader";
import adminUsersLoader from "./views/Admin/Users/loader";
import adminSurveysLoader from "./views/Admin/Surveys/loader";
import adminLoader from "./views/Admin/loader";
import regulatoryHubLoader from "./views/RegulatoryHub/loader";
import feedbackLoader from "./views/RegulatoryHub/feedbackLoader";
import chatbotPageLoader from "./views/Chatbot/loader";
import chatbotsLoader from "./views/ChatbotDashboard/loader";
import regulatoryActionsLoader from "./views/RegulatoryHub/Actions/loader";

const routes: RouteObject[] = [
  {
    errorElement: <RouteError />,
    path: "/",
    loader: () => {
      const isMaintenance = import.meta.env.VITE_IS_MAINTENANCE === "true";
      if (isMaintenance) {
        return throwErrorResponse({
          status: 503,
          statusText: "Maintenance",
        });
      }
      return null;
    },
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Navigate
              to={{
                pathname: "app",
                search: window.location.search,
              }}
              replace
            />
          </ProtectedRoute>
        ),
      },
      {
        path: "regulatory-hub/feedback",
        lazy: async () => {
          const RegHubFeedback = await import("@/views/RegulatoryHub/Feedback");
          return { Component: RegHubFeedback.default };
        },
        loader: feedbackLoader,
      },
      {
        path: "newsletter-sign-up",
        lazy: async () => {
          const NewsletterSignUp = await import("@/views/NewsletterSignUp");
          return { Component: NewsletterSignUp.default };
        },
      },
      {
        path: "newsletter-unsubscribe",
        lazy: async () => {
          const NewsletterUnsubscribe = await import(
            "@/views/NewsletterUnsubscribe"
          );
          return { Component: NewsletterUnsubscribe.default };
        },
      },
      {
        path: "auth",
        lazy: async () => {
          const Auth = await import("@/views/Auth");
          return { Component: Auth.default };
        },
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={{ pathname: "login", search: window.location.search }}
                replace
              />
            ),
          },
          {
            path: "login",
            lazy: async () => {
              const Login = await import("@/views/Auth/Login");
              return { Component: Login.default };
            },
          },
          {
            path: "sign-up",
            lazy: async () => {
              const SignUp = await import("@/views/Auth/SignUp");
              return { Component: SignUp.default };
            },
          },
          {
            path: "forgot-password",
            lazy: async () => {
              const ForgotPassword = await import(
                "@/views/Auth/ForgotPassword"
              );
              return { Component: ForgotPassword.default };
            },
          },
          {
            path: "resend-verification",
            lazy: async () => {
              const ResendVerification = await import(
                "@/views/Auth/ResendVerification"
              );
              return { Component: ResendVerification.default };
            },
          },
        ],
      },
      {
        path: "app",
        id: "app",
        loader: appLoader,
        children: [
          {
            index: true,
            lazy: async () => {
              const App = await import("@/views/App");
              return { Component: App.default };
            },
          },
          {
            path: "workspaces",
            lazy: async () => {
              const Workspaces = await import("@/views/Workspaces");
              return { Component: Workspaces.default };
            },
            children: [
              {
                path: ":workspaceId",
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={{
                          pathname: "home",
                          search: window.location.search,
                        }}
                        replace
                      />
                    ),
                  },
                  {
                    path: "home",
                    loader: homeLoader,
                    lazy: async () => {
                      const Home = await import("@/views/Home");
                      return { Component: Home.default };
                    },
                  },
                  {
                    path: "reports",
                    loader: reportsLoader,
                    lazy: async () => {
                      const Reports = await import("@/views/Reports");
                      return { Component: Reports.default };
                    },
                  },
                  {
                    path: "reports/new",
                    lazy: async () => {
                      const CreateReport = await import(
                        "@/views/Report/Create"
                      );
                      return { Component: CreateReport.default };
                    },
                  },
                  {
                    path: "reports/:documentId",
                    loader: questionnaireDetailsLoader,
                    lazy: async () => {
                      const reportDetails = await import(
                        "@/views/Report/Details"
                      );
                      return { Component: reportDetails.default };
                    },
                  },
                  {
                    path: "questionnaires",
                    loader: questionnaireLoader,
                    lazy: async () => {
                      const Questionnaires = await import(
                        "@/views/Questionnaires"
                      );
                      return { Component: Questionnaires.default };
                    },
                  },
                  {
                    path: "questionnaires/new",
                    lazy: async () => {
                      const QuestionnaireDetails = await import(
                        "@/views/Questionnaire/Create"
                      );
                      return { Component: QuestionnaireDetails.default };
                    },
                  },
                  {
                    path: "questionnaires/:documentId",
                    loader: questionnaireDetailsLoader,
                    lazy: async () => {
                      const QuestionnaireDetails = await import(
                        "@/views/Questionnaire/Details"
                      );
                      return { Component: QuestionnaireDetails.default };
                    },
                  },
                  {
                    path: "chatbots",
                    loader: chatbotsLoader,
                    lazy: async () => {
                      const Chatbot = await import("@/views/ChatbotDashboard");
                      return { Component: Chatbot.default };
                    },
                  },
                  {
                    path: "chatbots/new",
                    lazy: async () => {
                      const CreateChatbot = await import(
                        "@/views/Chatbot/Create"
                      );
                      return { Component: CreateChatbot.default };
                    },
                  },
                  {
                    path: "chatbots/:chatbotId",
                    lazy: async () => {
                      const Chatbot = await import("@/views/Chatbot");
                      return { Component: Chatbot.default };
                    },
                    loader: chatbotPageLoader,
                  },
                  {
                    path: "resources",
                    lazy: async () => {
                      const Resources = await import(
                        "@/views/Resources/loaderIndex"
                      );
                      return { Component: Resources.default };
                    },
                    loader: resourcesLoader,
                  },
                  {
                    path: "settings",
                    loader: workspaceSettingsLoader,
                    lazy: async () => {
                      const WorkspaceSettings = await import(
                        "@/views/WorkspaceDetails/Settings"
                      );
                      return { Component: WorkspaceSettings.default };
                    },
                  },
                  {
                    path: "regulatory-hub",
                    loader: regulatoryHubLoader,
                    lazy: async () => {
                      const RegCompDashboard = await import(
                        "@/views/RegulatoryHub/"
                      );
                      return { Component: RegCompDashboard.default };
                    },
                  },
                  {
                    path: "regulatory-hub/analysis/:chatbotId",
                    lazy: async () => {
                      const Analysis = await import(
                        "@/views/RegulatoryHub/Analysis"
                      );
                      return { Component: Analysis.default };
                    },
                    loader: chatbotPageLoader,
                  },
                  {
                    path: "regulatory-hub/actions/:regulatoryUpdateId",
                    lazy: async () => {
                      const RegulatoryActions = await import(
                        "@/views/RegulatoryHub/Actions"
                      );
                      return { Component: RegulatoryActions.default };
                    },
                    loader: regulatoryActionsLoader,
                  },
                  {
                    path: "account",
                    lazy: async () => {
                      const AccountSettings = await import(
                        "@/views/AccountSettings"
                      );
                      return { Component: AccountSettings.default };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "admin",
        loader: adminLoader,
        lazy: async () => {
          const Admin = await import("@/views/Admin");
          return { Component: Admin.default };
        },
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={{ pathname: "users", search: window.location.search }}
                replace
              />
            ),
          },
          {
            path: "users",
            loader: adminUsersLoader,
            lazy: async () => {
              const UserQA = await import("@/views/Admin/Users");
              return { Component: UserQA.default };
            },
          },
          {
            path: "surveys",
            loader: adminSurveysLoader,
            lazy: async () => {
              const Surveys = await import("@/views/Admin/Surveys");
              return { Component: Surveys.default };
            },
          },
        ],
      },
      {
        path: "account",
        loader: appLoader,
        lazy: async () => {
          const Unsubscribe = await import(
            "@/views/AccountSettings/UnsubscribeRedirect"
          );
          return { Component: Unsubscribe.default };
        },
      },
      {
        path: "regulatory-hub",
        loader: appLoader,
        lazy: async () => {
          const RegHubRedirect = await import(
            "@/views/RegulatoryHub/RegulatoryHubRedirect"
          );
          return { Component: RegHubRedirect.default };
        },
        children: [
          {
            path: "chatbots/:chatbotId",
            lazy: async () => {
              const RegHubRedirect = await import(
                "@/views/RegulatoryHub/RegulatoryHubRedirect"
              );
              return { Component: RegHubRedirect.default };
            },
          },
        ],
      },
    ],
  },
];

let browserRouter = createBrowserRouter;
if (import.meta.env.VITE_SENTRY_DSN) {
  void (async () => {
    const Sentry = await import("@sentry/react");
    browserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  })();
}

const router = browserRouter(routes);

export default router;
